import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import global from '../api/global';
import ApprovalApi from '../api/approval';
import { useParams } from "react-router-dom";


function TopBar({ fn }) {
    const { t } = useTranslation();
    const { id } = useParams();
    const [name, setName] = useState('');
    //const [notify, setNotify] = useState([]);
    //const [message, setMessage] = useState([]);


    const user = async () => {
        let data = await global.getToken();
        setName(data.sub);
    }

    const getData = async () => {

        const getMessage = async () => {
            let { data: res } = await ApprovalApi.getByUser();
            localStorage.setItem(`approval[${global.getToken().id}]`, JSON.stringify(res))
            //console.log('<<< Message::', res);
            let datafilter = res.filter(el => el.source_id > 0);

            fn.setMessage(datafilter);
        }

        try {
            setInterval(async () => {
                getMessage()
            }, 30000);

            getMessage()
        } catch (e) {
            console.warn(e);
        }

    }

    useEffect(() => {
        user()
        getData();

        // setMessage([
        //     {
        //         id: 1,
        //         title: 'December 12, 2019',
        //         message: 'DDDDDDDDDDDDDDdddd'
        //     }
        // ])
        // fn.setNotify([
        //     {
        //         id: 2,
        //         title: 'December 12, 2019',
        //         message: 'DDDDDDDDDDDDDDdddd'
        //     }
        // ])

    }, [])

    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            {/* Sidebar Toggle (Topbar) */}
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars" />
            </button>
            {/* Topbar Search */}
            <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button">
                            <i className="fas fa-search fa-sm" />
                        </button>
                    </div>
                </div>
            </form>
            {/* Topbar Navbar */}
            <ul className="navbar-nav ml-auto">
                {/* Nav Item - Search Dropdown (Visible Only XS) */}
                <li className="nav-item dropdown no-arrow d-sm-none">
                    <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-search fa-fw" />
                    </a>
                    {/* Dropdown - Messages */}
                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                        <form className="form-inline mr-auto w-100 navbar-search">
                            <div className="input-group">
                                <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button">
                                        <i className="fas fa-search fa-sm" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>
                {/* Nav Item - Alerts */}
                <li className="nav-item dropdown no-arrow mx-1">
                    <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-bell fa-fw" />
                        {/* Counter - Alerts */}
                        {
                            fn.notify.length > 0 && fn.notify.map((el, index) => (
                                <span key={index + 1} className="badge badge-danger badge-counter">{fn.notify.length > 3 ? '3+' : fn.notify.length}</span>
                            ))
                        }

                    </a>
                    {/* Dropdown - Alerts */}
                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                        <h6 className="dropdown-header">
                            Alerts Center
                        </h6>
                        {
                            fn.notify.length > 0 && fn.notify.map((el, index) => (

                                <Link key={index + 1} to={`/approval/notify/${el.id}`} className="dropdown-item d-flex align-items-center" >
                                    <div className="mr-3">
                                        <div className="icon-circle bg-primary">
                                            <i className="fas fa-file-alt text-white" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">{el.title}</div>
                                        <span className="font-weight-bold">{el.message}</span>
                                    </div>
                                </Link>


                            ))
                        }
                        <Link to={`/approval/history`} className="dropdown-item text-center small text-gray-500" href="#">{t('Show All Alerts')}</Link>
                    </div>

                </li>
                {/* Nav Item - Messages */}
                <li className="nav-item dropdown no-arrow mx-1">
                    <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-envelope fa-fw" />
                        {/* Counter - Messages */}
                        {/* <span className="badge badge-danger badge-counter">7</span> */}
                        {
                            fn.message.length > 0 && fn.message.map((el, index) => (
                                <span key={index + 1} className="badge badge-danger badge-counter">{fn.message.length}</span>
                            ))
                        }
                    </a>
                    {/* Dropdown - Messages */}
                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
                        <h6 className="dropdown-header">
                            {t('Message Center')}
                        </h6>
                        {
                            fn.message.length > 0 && fn.message.map((el, index) => (

                                <Link key={el.id} to={`/approval/${el.id}`} className="dropdown-item d-flex align-items-center">
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src="/assets/img/file.svg" alt="..." />
                                        <div className={`status-indicator ${el.type === 1 ? 'bg-success' : 'bg-info'}`} />
                                    </div>
                                    <div className="font-weight-bold">
                                        <div className="text-truncate">{el.source_des}</div>
                                        <div className="small text-gray-500">{el.source_no}</div>
                                    </div>
                                </Link>

                            ))
                        }
                        <Link to={`/approval`} className="dropdown-item text-center small text-gray-500" href="#">{t('Read More Messages')}</Link>
                    </div>

                </li>
                <div className="topbar-divider d-none d-sm-block" />
                {/* Nav Item - User Information */}
                <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{name}</span>
                        <img className="img-profile rounded-circle" src="/assets/img/undraw_profile.svg" />
                    </a>
                    {/* Dropdown - User Information */}
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                        <Link className="dropdown-item" to={`/user/profile`}>
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                            {t('Profile')}
                        </Link>
                        <Link className="dropdown-item" to={`/user/setting`}>
                            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                            {t('Settings')}
                        </Link>
                        <Link className="dropdown-item" to={`/approval/history`}>
                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                            {t('Activity Log')}
                        </Link>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                            {t('Logout')}
                        </a>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default TopBar;