import React, { useRef, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import SwitchLang from './language';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import _global from "../api/global";
import '../fileUpload.css';

function Attached({ payload }) {
    const { t } = useTranslation();
    const [openFile, setOpenFile] = useState(null);

    const viewFile = async (file) => {
        try {
            setOpenFile(file);
            const { data: response } = await _global.client.get("/v1/file", { params: { id: file.id } });
            console.warn('get file response::', response);
        } catch (e) {
            console.error('error view file::', e);
        }
    }

    return (
        <div className='row'>

            {
                payload?.attach && payload?.attach.map((el, index) => (
                    <div key={index + 1} className={`col-12 font-12 `}>
                        <div className='border p-2 d-flex justify-content-between bg-red-light' >
                            <div className="text-info cursor-pointer" onClick={() => viewFile(el)}>
                                {el.title}
                            </div>
                            <div className={``}>
                                {el.setting && _global.formatBytes(JSON.parse(el.setting).size)}
                            </div>
                        </div>
                    </div>
                ))
            }

            <div className={`modal-file ${openFile ? 'd-block' : 'd-none'}`}>
                <div className='d-flex justify-content-between modal-file-head'>
                    <div className=' ' style={{ paddingLeft: 20, paddingTop: 10, fontSize: 20, color: '#fff' }}>
                        <div className='btn-modal-file' onClick={() => setOpenFile(false)}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                    </div>
                    <div className=' ' style={{ paddingTop: 10, paddingRight: 20, color: '#fff' }}>
                        <div className='btn-modal-file'>
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                        </div>
                    </div>
                </div>
                <div className='modal-file-body' >

                    {
                        openFile?.name && <iframe
                            src={`${_global.config.api}/uploads/${openFile?.name}`}
                            frameBorder="0"
                            height={'100%'}
                            width={'100%'}
                        />
                    }

                </div>
            </div>
        </div>
    )
}

export default Attached;