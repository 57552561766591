import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import global from './global';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    faBan,
    faEdit, faPlusCircle, faPowerOff, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment/moment';



const getByUser = async () => {
    try {
        let response = await global.client.get(`/v1/approval/0`);

        let list = [];
        for (let row of response.data) {
            list.push({
                ...row,
                payload: row.payload ? JSON.parse(row.payload) : []
            })
        }
        //console.log('response::', list)
        return { data: list };
    } catch (error) {
        console.error(error);
    }
}

const getTransaction = async (user,name) => { ///v1/approval/transaction/{user}/{name}
    try {
        let response = await global.client.get(`/v1/approval/transaction/${user}/${name}`);

        let list = [];
        for (let row of response.data) {
            list.push({
                ...row,
                payload: row.payload ? JSON.parse(row.payload) : []
            })
        }
        console.log('transaction response::', list)
        return { data: list };
    } catch (error) {
        console.error(error);
    }
}

const search = async (value, from, to) => {
    try {
        let response = await global.client.get(`/v1/approval`, {
            params:
            {
                type: value,
                status: [1, 3],
                from: from,
                to: to
            }
        });

        let list = [];
        for (let row of response.data) {
            list.push({
                ...row,
                payload: row.payload ? JSON.parse(row.payload) : []
            })
        }
        //console.log('response::', list)
        return { data: list };
    } catch (error) {
        console.error(error);
    }
}

const getData = async (id) => {
    try {
        let response = await global.client.get(`/v1/approval/${id}`);
        //console.log('response::', response.data)
        return response;
    } catch (error) {
        console.error(error);
    }
}


const update = async (body, id) => {
    try {
        return await global.client.patch(`/v1/approval/${id}`, body);
    } catch (e) {
        console.error('error::', e);
    }
}


const TableList = (props) => {

    const [deleteRow, setDeleteRow] = useState({});
    const edit = (row) => {
        props.controller.setForm({}); // clear First
        props.controller.setEditable(true)
        props.controller.setForm(row)
    }

    const newDoc = () => {
        props.controller.setEditable(true)
        props.controller.setForm(props.controller.default)
    }

    const confirmDelete = () => {
        props.controller.remove(deleteRow)
    }

    const Title = ({ controller }) => {
        return <>
            <div className='row'>
                <div className='col'>
                    <h3 className=" mb-2 text-gray-800">{t('item journal')}</h3>
                </div>
                <div className='col text-right'>
                    <button
                        type="button"
                        className='btn btn-outline-success btn-sm'
                        onClick={() => newDoc()}
                        data-toggle="modal"
                        data-target="#addModalOrder"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} /> {t('Add')}
                    </button>
                </div>
            </div>

        </>
    }
    return <div>
        <FormAddData controller={props.controller} />
        <div className="modal fade" id="modalDeleteJournal" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-danger text-white">
                        <h5 className="modal-title">Confirm</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>{t('comfirn delete')}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={() => confirmDelete()} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                    </div>
                </div>
            </div>
        </div>

        <div className={`${props.controller.editable && 'd-none'}`}>
            <DataTable
                title={<Title controller={props.controller} />}
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                columns={[
                    {
                        name: t('date'),
                        selector: row => moment(row.create_date).format('YYYY-MM-DD'),
                        sortable: true,
                        maxWidth: '100px',
                        id: "create_date"
                    },
                    {
                        name: t('document no'),
                        selector: row => row.no,
                        sortable: true,
                        maxWidth: '150px',
                        id: "no"
                    },
                    {
                        name: t('customer name'),
                        selector: row => row.header,
                        sortable: true,
                        id: "header"
                    },
                    {
                        name: t('document source'),
                        selector: row => row.source_no,
                        sortable: true,
                        maxWidth: '150px',
                        id: "source_no"
                    },
                    {
                        name: t('Action'),
                        selector: row => row.id,
                        id: "action",
                        width: '150px',
                        cell: (row) => <div className='w-100 text-right'>
                            <button className="btn btn-warning btn-xs ml-1" onClick={() => edit(row)} ><FontAwesomeIcon icon={faEdit} /> {t('edit')} </button>
                            <button className="btn btn-danger btn-xs ml-1" onClick={() => setDeleteRow(row)} data-toggle="modal" data-target="#modalDeleteJournal"><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')} </button>
                        </div >
                    }

                ]}
                data={props.data || []}
                sortFunction={global.customSort}
                defaultSortFieldId="code"
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />
        </div>
    </div>;


}


const FormAddData = ({ controller }) => {
    const [showModal, setShowModal] = useState(false);
    const [fadeHide, setFadeHide] = useState('');


    let form = controller.form;
    //console.log('searchOrder::', controller);

    useEffect(() => {
        if (!form.source_no) {
            setShowModal(true)
        } else {
            setShowModal(false)
        }
    }, [form])


    const deleteLine = (item) => {
        let newItems = form.items.filter(el => el.id !== item.id);
        controller.setForm({ ...form, ...{ items: newItems } })
    }

    const updateForm = (name, value) => {
        let newForm = {
            ...form,
            [name]: value
        }
        controller.setForm(newForm)
    }

    const updateLine = (name, event) => {
        console.log('name::', name, '=>', event.target);


        const element = event.target.parentElement;
        const tr = element.closest("tr");
        let id = tr.id;

        let newItem = [];
        for (let item of form.items) {
            if (Number(item.id) === Number(id)) {
                let value = event.target.value;

                // Validate from sale line
                // if (name === 'qty') {
                //     console.log('event.target.value > item.qty', event.target.value, item.qty)
                //     if (event.target.value > item.qty) {
                //         value = item.qty;
                //     } else {
                //         value = event.target.value;
                //     }
                // }

                newItem.push({
                    ...item,
                    [name]: value
                })
            } else {
                newItem.push(item);
            }
        }
        console.log('new item::', newItem);

        controller.setForm({ ...form, items: newItem })
    }

    const ModalSearch = ({ children, ModalName }) => {

        return <>

            <div className={`absolute-card shadow ${showModal ? 'd-block' : 'd-none'}`} >
                <div className="card  shadow">
                    <div className="card-header bg-warning">
                        <button type="button" className="close" onClick={() => setShowModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body text-start">
                        <h5 className="card-title">{t('document source')}</h5>
                        <input
                            name="source_no"
                            type={'text'}
                            className="form-control form-control-sm d-print-noline"
                            placeholder={t('input sale order number.')}
                            value={form.source_no || ''}
                            onChange={(el) => controller.getSource(el.target.value)}
                        />

                    </div>
                </div>
            </div>

            <div className={`shadow ${controller.searchOrder.length > 0 ? 'd-block' : 'd-none'} `}
                style={{
                    position: 'absolute',
                    left: 0,
                    top: '65%',
                    width: '100%',
                    backgroundColor: "#3a3b45d1",
                    color: "#FFF",
                    zIndex: 1061,
                    padding: 15,
                    border: '1px solid rgb(204, 204, 204)',
                    borderRadius: 5
                }}
            >
                <hr />
                <div style={{ padding: 10 }}>
                    {
                        controller.searchOrder.length > 0 && controller.searchOrder?.map((el, index) => (
                            <div className="row row-hover" key={index + 1} onClick={() => controller.clickSelectSource(el)}>
                                <div className="col-3 bg-secondary text-white pb-2"><b>{el.document_no}</b></div>
                                <div className="col border border-secondary">
                                    <b>{moment(el.order_date).format('MM/DD/YYYY')}</b></div>
                                <div className="col border border-secondary">{el.cust_name}</div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </>
    }

    return <>
        <div className={`${!controller.editable && 'd-none'} mb-5`} style={{
            border: '0px solid #ccc',
            padding: 0,
            background: "#fff",
            position: 'relative'
        }}>
            <ModalSearch />
            <table className='table table-bordered' >
                <thead>
                    <tr>
                        <th className='text-center' colSpan={'4'}>
                            <h5>{t('inventory request')}</h5>
                            <div className='font-13'>Request Inventory</div>
                        </th>
                        <th colSpan={'2'} width="20%" className='text-center'>
                            <img src="/assets/images/thammasak-q.png" className='w-100' style={{ maxWidth: 120 }} />
                        </th>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td colSpan={'3'} rowSpan={'3'}>
                            <div className='mb-5 d-flex'>
                                <span style={{ minWidth: 50 }}>{t('customer name')}</span> :
                                <span className='text-primary w-100 ml-2' style={{ marginTop: -6 }}>
                                    <textarea
                                        className="form-control d-print-noline font-12"
                                        id="header"
                                        rows="3"
                                        value={form.header || ''}
                                        onChange={(el) => updateForm('header', el.target.value)}
                                    />
                                </span>
                            </div>
                            <div className='d-flex'>
                                <span style={{ minWidth: 50 }}>{t('Project')}</span> :
                                <span className='text-primary w-100 ml-2' style={{ marginTop: -6 }}>
                                    <input
                                        name="detail"
                                        type={'text'}
                                        className="form-control form-control-sm d-print-noline"
                                        defaultValue={form.detail || ''}
                                        onChange={(el) => updateForm('detail', el.target.value)}
                                    />
                                </span>
                            </div>
                        </td>
                        <td width="10%" style={{ minWidth: 120 }}>{t('document no')}</td>
                        <td colSpan={'2'}>
                            <input
                                name="no"
                                type={'text'}
                                className="form-control form-control-sm d-print-noline"
                                defaultValue={form.no || ''}
                                onChange={(el) => updateForm('no', el.target.value)}
                            />
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td>{t('document source')}</td>
                        <td colSpan={'2'}>
                            <input
                                name="source_no"
                                type={'text'}
                                className="form-control form-control-sm d-print-noline"
                                placeholder={t('input sale order number.')}
                                value={form.source_no || ''}
                                onChange={(el) => controller.getSource(el.target.value)}
                            />
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td>{t('date request')}</td>
                        <td colSpan={'2'}>
                            <DatePicker
                                selected={form.create_date ? new Date(form.create_date) : new Date()}
                                dateFormat="dd/MM/yyyy"
                                className="form-control form-control-sm d-print-noline"
                                onChange={(date) => updateForm('create_date', moment(date).toISOString())}
                            />
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td width="2%">{t('No.')}</td>
                        <td width="15%">{t('item code')}</td>
                        <td>{t('request descripton')}</td>
                        <td>{t('request amount')}</td>
                        <td>{t('unit')}</td>
                        <td>{t('remark')}</td>
                    </tr>
                </thead>

                <tbody className='font-12'>
                    {
                        form.items?.length > 0 && form.items.map((item, index) => (
                            <tr key={index + 1} id={item.id}>
                                <td className='text-center'>{index + 1}</td>
                                <td>{item?.item_code}</td>
                                <td>{item.name}</td>
                                <td className=''>
                                    <input
                                        name="qty"
                                        type={'number'}
                                        className="form-control form-control-sm d-print-noline text-right"
                                        value={Number(item?.qty)}
                                        style={{ marginTop: -7 }}
                                        onChange={(el) => updateLine('qty', el)}
                                    />
                                </td>
                                <td>{item.unit}</td>
                                <td>
                                    <div style={{ position: 'relative' }}>
                                        <input
                                            name="remark"
                                            type={'text'}
                                            className="form-control form-control-sm d-print-noline"
                                            value={item?.remark}
                                            style={{ marginTop: -7 }}
                                            onChange={(el) => updateLine('remark', el)}
                                        />
                                        <div
                                            className='delete-row text-danger cursor-pointer disabled-print'
                                            onClick={() => deleteLine(item)}
                                        >
                                            <FontAwesomeIcon icon={faBan} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))

                    }
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={'6'} className="font-13">
                            remark :
                        </th>
                    </tr>
                    <tr>
                        <th colSpan={'6'} className="font-12">
                            {/* <div className=" font-12 w-100">
                                <div className="row" style={{ border: '1px solid #ccc' }}>
                                    <div className="col-12  "  >
                                        <div className='row'>
                                            <div className="col-12">
                                                font-13
                                            </div>
                                            <div className="col-12">
                                                font-13
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </>
}


const ApprovalApi = {
    getByUser,
    getTransaction,
    search,
    getData,
    TableList,
    FormAddData,
    update
};

export default ApprovalApi;