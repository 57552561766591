import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Template from '../../component/template';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faTimes } from '@fortawesome/free-solid-svg-icons';
import _global from '../../api/global';
import moment from 'moment';
import ApprovalApi from '../../api/approval';
import Attached from '../../component/attached';

const Approval = () => {
    const { t } = useTranslation();
    let { id } = useParams();
    const [loadData, setLoadData] = useState([]); // data for top-bar
    const [data, setData] = useState([]); // data for this page
    const [modal, setModal] = useState({});
    const [notify, setNotify] = useState([])
    const [message, setMessage] = useState([]);
    const hiddenFileInput = useRef(null);
    const [openFile, setOpenFile] = useState(null);
    const [form, setForm] = useState({});

    const controller = {
        form,
        setForm
    }

    const fetchData = async () => {
        try {
            let rawData = localStorage.getItem(`approval[${_global.getToken().id}]`);
            let raw = rawData ? JSON.parse(rawData) : [];
            setLoadData(raw);

            /**
             * ถ้าไม่มี id ให้แสดงทั้งหมด
             */
            let datafilter = typeof id !== 'undefined'
                ? raw.filter(el => Number(el.id) === Number(id))
                : raw.filter(el => el.source_id > 0);
            //console.log('data filter::', id, datafilter);

            /**
             * Filter only approve->uuid = source->uuid
             */
            // datafilter = datafilter.map(el => {
            //     return el.uuid === 
            // })
            setData(datafilter);
        } catch (e) {
            console.warn('error storage::', e);
        }
    }

    useEffect(() => {
        fetchData();
    }, [id])

    const openApproveModal = (approval, type) => {
        setModal({ ...approval, type: type });
    }

    const confirm = async (type) => {
        try {
            let body = {
                status: 0,
                approve_when: moment(),
                message: modal.message
            }

            if (type === 'approve') {
                body.status = 1;
            } else {
                body.status = 3;
            }

            let { data: res } = await ApprovalApi.update(body, modal.id);
            console.log('change status::', res);
            if (res.status !== 2) throw new Error(res);

            let newData = data.filter(el => el.id !== modal.id);
            setData(newData);

            let newloadData = loadData.filter(el => el.id !== modal.id);
            setMessage(newloadData);
            localStorage.setItem(`approval[${_global.getToken().id}]`, JSON.stringify(newloadData));

        } catch (e) {
            console.error('e', e);
        }
    }



    const viewFile = async (file) => {
        try {
            setOpenFile(file);
            const { data: response } = await _global.client.get("/v1/file", { params: { id: file.id } });
            console.warn('get file response::', response);
        } catch (e) {
            console.error('error view file::', e);
        }
    }

    const attachClick = () => {
        hiddenFileInput.current.click();
    };

    const removeFile = async (file) => {
        console.log('file remove::', file);
        let newFiles = controller.form?.attach || [];


        // api remove file
        // try {
        //     const { data: response } = await _global.client.delete("/v1/file", { params: { id: file.id } });
        //     console.warn('remove response::', response);
        //     if (response.status === 2) {
        //         newFiles = newFiles.filter(el => el.id !== file.id);
        //         controller.setForm({ ...controller.form, attach: newFiles })
        //     }
        // } catch (e) {
        //     console.error('error remove file::', e);
        // }

        newFiles = newFiles.filter(el => el.id !== file.id);
        controller.setForm({ ...controller.form, attach: newFiles })

    }

    const handleFileSelect = async (e) => {

        let newFiles = controller.form?.attach || [];

        for (let file of e.target.files) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file, "UTF-8");
            let name = file.name;
            let extension = file.name.split('.').pop();
            //setFileExt(extension);
            ///setFileName(name)

            fileReader.onload = async (e) => {
                let rawData = Buffer.from(e.target.result, 'base64');
                //setFiles(rawData);


                try {
                    let body = {
                        title: name,
                        files: rawData,
                        ext: extension,
                        setting: JSON.stringify({
                            name: name,
                            ext: extension
                        })
                    }
                    const { data: response } = await _global.client.post("/v1/file", body);
                    console.log('response::', response);
                    if (response.status === 2) {
                        newFiles.push(response.data)
                        controller.setForm({ ...controller.form, attach: newFiles })
                    }
                } catch (error) {
                    console.log(error)
                }
            };
        }
    }


    return (
        <Template notify={notify} setNotify={setNotify} message={message} setMessage={setMessage}>
            <div className="modal fade" id="approveModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdrop" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header bg-info">
                            <h5 className="modal-title text-white" id="staticBackdropLabel">{t(modal?.type)}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            <div>
                                {
                                    modal?.type === 'reject'
                                        ? <div>
                                            <span className='text-primary'>{t('reason')}</span>
                                            <textarea
                                                type={'text'}
                                                name="message"
                                                className='form-control my-2'
                                                onChange={(el) => setModal({ ...modal, message: el.target.value })}
                                            />
                                        </div>
                                        : ''
                                }
                            </div>
                            <div className="d-flex justify-content-between mt-4">
                                <button
                                    className="btn btn-secondary "
                                    data-dismiss="modal"
                                >{t('cancel')}</button>
                                <button
                                    onClick={() => confirm(modal?.type)}
                                    className="btn btn-primary ml-3"
                                    data-dismiss="modal"
                                >{t('confirm')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                {
                    data.length > 0
                        ? data.map((el, index) => (
                            <div key={index + 1} className="col-12 col-lg-6 mb-5">
                                <div className="card border-info" >
                                    <div className={`card-header text-white ${el.type === 1 ? 'bg-success' : 'bg-info'}`}>
                                        <b>{t(el.source_des)}</b>
                                    </div>
                                    <div className="card-body" style={{ minHeight: 200 }}>
                                        <h5 className="card-title">{t(el.source_no)}</h5>
                                        <h6 className="card-subtitle mb-2 text-muted ">{moment(el.datetime).format('DD/MM/YYYY H:m:s')}</h6>
                                        <div>
                                            {t(el.payload?.cust_name)}
                                        </div>
                                        <div>
                                            {t(el.payload?.cust_contact)}
                                        </div>
                                        <div className="card-text">
                                            <div className="row">
                                                <table className='table table-bordered font-12'>
                                                    <tbody>
                                                        {
                                                            el.payload && el.payload?.items?.length > 0 && el.payload.items.map((item, i) => (
                                                                <tr key={item.id}>
                                                                    <td width="20px">{i + 1}</td>
                                                                    <td>{item.item_code}</td>
                                                                    <td>{item.name}</td>
                                                                    <td className='text-right'>{_global.NumberFormat(item.qty)}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                <div className='col-12 font-10'>{el.remark}</div>
                                                <div className='col-12 font-10'>{el.payload?.remark}</div>
                                                <div className="col-12">
                                                    <Attached payload={el.payload} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <div>
                                            {/* <button className='btn btn-info'>
                                            <FontAwesomeIcon icon={faEye} />
                                            <span className='ml-1'>{t('review')}</span>
                                        </button> */}
                                            <button
                                                className='btn btn-warning ml-2'
                                                data-toggle="modal"
                                                data-target="#approveModal"
                                                onClick={() => openApproveModal(el, 'reject')}
                                            >
                                                <FontAwesomeIcon icon={faClose} />
                                                <span className='ml-1'>{t('reject')}</span>
                                            </button>
                                        </div>
                                        <div>

                                            <button
                                                className='btn btn-success ml-2'
                                                data-toggle="modal"
                                                data-target="#approveModal"
                                                onClick={() => openApproveModal(el, 'approve')}>
                                                <FontAwesomeIcon icon={faCheck} />
                                                <span className='ml-1'>{t('approve')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        : <div className='text-center w-100'>{t('There are no records to display')}</div>
                }
            </div>
        </Template>
    );
}

export default Approval;
