import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SideBar from '../component/sidebar'
import Table from '../component/table';
import TopBar from '../component/top-bar';
import Dashboard from '../component/dashboard';
import Footer from '../component/footer';
import Modal from '../component/modal';
import { useNavigate } from 'react-router-dom';



const Template = (props) => {
    const navigate = useNavigate();
    const [notify, setNotify] = useState([])
    const [message, setMessage] = useState([]);
    useEffect(() => {

        if (!localStorage.getItem('token')) {
            navigate('/login', { replace: false });
        }

        // setNotify([
        //     {
        //         id: 2,
        //         title: 'December 12, 2019',
        //         message: 'DDDDDDDDDDDDDDdddd'
        //     }
        // ])

    }, [])


    const { t } = useTranslation();

    const fn = {
        notify : props.notify || notify,
        setNotify: props.setNotify || setNotify,
        message: props.message || message,
        setMessage: props.setMessage || setMessage 
    }

    return (
        <div>
            <div id="wrapper">
                <SideBar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar fn={fn} />
                        <div className="container-fluid">
                            {props.children}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#root">
                <i className="fas fa-angle-up" />
            </a>
            <Modal />
        </div>
    );
}

export default Template;
