import { t } from 'i18next';
import moment from 'moment';
import jwt_decode from "jwt-decode";
import CategoryApi from './category';
const config = require(`../config/${process.env.NODE_ENV}`).default;
const { client } = require('./auth').default;

const getUserFromToken = async () => {
    let token = localStorage.getItem('token');
    //console.log('user >>', jwt_decode(token)['id']);
    return jwt_decode(token)['id'];
}

const getToken = () => {
    let token = localStorage.getItem('token');
    return jwt_decode(token);
}

const JobStatus = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 0:
            className = 'text-secondary';
            text = t('Created');
            break;
        case 1:
            className = 'text-warning';
            text = t('Pending');
            break;
        case 2:
            className = 'text-success';
            text = t('Confirmed');
            break;
        case 3:
            className = 'text-info';
            text = t('Waiting for payment');
            break;
        case 1:
            className = 'text-success';
            text = t('Paid');
            break;

        case 4:
            className = 'text-muted';
            text = t('Waiting for the transfer slip');
            break;
        case 5:
            className = 'text-success';
            text = t('Paid');
            break;
        case 9:
            className = 'text-muted';
            text = t('Cancled');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}

const ItemSource = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 1:
            className = 'text-info';
            text = t('Inside');
            break;
        case 2:
            className = 'text-primary';
            text = t('Outside');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}

const ItemType = (ItemType) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(ItemType)) {
        case 0:
            className = 'text-info';
            text = t('Finished Goods');
            break;
        case 1:
            className = 'text-primary';
            text = t('Assembly');
            break;
        case 2:
            className = 'text-primary';
            text = t('Raw Materials');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}

const CustomerStatus = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 0:
            className = 'text-secondary';
            text = t('Created');
            break;
        case 1:
            className = 'text-success';
            text = t('Active');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}

const UserStatus = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 0:
            className = 'text-secondary';
            text = t('Disabled');
            break;
        case 1:
            className = 'text-success';
            text = t('Active');
            break;
        case 3:
            className = 'text-warning';
            text = t('Suspend');
            break;
        case 4:
            className = 'text-danger';
            text = t('Deleted');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}


const PaymentStatus = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 0:
            className = 'text-secondary';
            text = t('Pay');
            break;
        case 1:
            className = 'text-info';
            text = t('Wait');
            break;
        case 2:
            className = 'text-success';
            text = t('Approved');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}

const locations = () => {
    return [
        { id: 1, name: t('Bangkok') },
        { id: 2, name: t('Krabi') }
    ];
}

const NumberFormat = (value, digit = 2) => Number(value).toLocaleString(navigator.language, { minimumFractionDigits: digit });

const Totals = async (lines, discount = 0) => {
    let lineAmount = 0;
    let totalAmount = 0;
    let totalCost = 0;
    lines?.forEach(item => {
        lineAmount += parseFloat(item.qty) * parseFloat(item.date_amount) * parseFloat(item.price);
        totalAmount += parseFloat(item.date_amount);
        totalCost += parseFloat(item.date_amount) * parseFloat(item.cost);
    })

    /**
     * Total
     */
    let totalDiscount = Number(discount);
    let vat7 = Number(lineAmount - totalDiscount) * 7 / 100;
    let totals = Number(lineAmount + vat7);

    //let totalCost = Number(line.date_amount * line.cost);


    let result = {
        lineAmount: lineAmount,
        totalDiscount: totalDiscount,
        totalCost: totalCost,
        vat: vat7,
        totals: totals,
        profitTotal: (lineAmount - totalCost) - discount
    }
    console.log('total result::', result);
    return result;
}

const changeTime = (event, date) => {
    const time = event.target.value.split(':');
    let dateTime = moment(date).set({ h: time[0], m: time[1] }).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    console.log('date-time::', dateTime);
    return dateTime;
}

const numberOnly = (event) => {
    if (!/[0-9.]/.test(event.key)) event.preventDefault();
}

const getCatName = (id, category) => {
    let name;
    category.filter((cat) => (cat.id === id) ? name = cat.name : '')
    return name;
}

const mapCategory = async (data) => {
    let cat = await CategoryApi.getDataList();
    localStorage.setItem('category', JSON.stringify(cat));

    let newData = [];
    data?.sort((a, b) => {
        return a.name - b.name;
    });

    console.log('sort', data)
    data?.map((el) =>
        newData.push({
            ...el,
            category_name: getCatName(el.category, cat)
        })
    )


    console.log('sort', newData)
    return newData;
}


const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
        // use the selector function to resolve your field names by passing the sort comparitors
        const aField = selector(rowA)
        const bField = selector(rowB)

        let comparison = 0;

        if (aField > bField) {
            comparison = 1;
        } else if (aField < bField) {
            comparison = -1;
        }

        return direction === 'desc' ? comparison * -1 : comparison;
    });
};

const clearCached = (key = 'products') => {
    if (key === 'products') {
        localStorage.removeItem('products');
    }
}


const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}



export default {
    JobStatus,
    CustomerStatus,
    UserStatus,
    PaymentStatus,
    ItemSource,
    locations,
    NumberFormat,
    config,
    Totals,
    changeTime,
    getUserFromToken,
    numberOnly,
    getToken,
    mapCategory,
    client,
    customSort,
    clearCached,
    ItemType,
    formatBytes
};