import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Template from '../../component/template';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faEye, faHourglass, faSadTear } from '@fortawesome/free-solid-svg-icons';
import _global from '../../api/global';
import moment from 'moment';
import ApprovalApi from '../../api/approval';
import Attached from '../../component/attached';

const ApproveHistory = () => {
    const { t } = useTranslation();
    let { id } = useParams();
    const [loadData, setLoadData] = useState([]); // data for top-bar
    const [data, setData] = useState([]); // data for this page
    const [modal, setModal] = useState({});
    const [notify, setNotify] = useState([])
    const [message, setMessage] = useState([]);

    const fetchData = async () => {
        try {
            let date = new Date();
            var from = new Date(date.getFullYear(), date.getMonth() - 1, 26);
            var to = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            let { data: res } = await ApprovalApi.getTransaction(_global.getToken().id, '*');

            // console.log('res::', res);

            let sort = [...res].sort((a, b) => {
                var c = new Date(a.datetime);
                var d = new Date(b.datetime);
                return d - c;
            });


            setData(sort);
            //setNotify(res);
        } catch (e) {
            console.warn('error storage::', e);
        }
    }

    useEffect(() => {
        fetchData();
    }, [id])


    return (
        <Template notify={notify} setNotify={setNotify} message={message} setMessage={setMessage}>


            <div className=" ">
                {
                    data.length > 0
                        ? data.map((el, index) => (
                            <div key={index + 1} className="accordion" id="accordionApproval">
                                <div className="card mb-3">
                                    <div className="card-header" id={"heading" + el.id}>
                                        <h2 className="mb-0">
                                            <div className="btn btn-link  d-flex justify-content-between"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target={"#collapse" + el.id}
                                                aria-expanded="true"
                                                aria-controls={"collapse" + el.id}
                                            >
                                                <div className='text-left'>
                                                    {t(el.source_des)}  {t(el.source_no)}
                                                    <div className='font-12 text-secondary'>{moment(el.approve_when).format('DD/MM/YYYY H:m:s')}</div>

                                                </div>
                                                <div>
                                                    {
                                                        el.status === 1
                                                            ? <div
                                                                className='label text-success ml-2' >
                                                                <FontAwesomeIcon icon={faCheck} />
                                                                <span className='ml-1'>{t('approve')}</span>
                                                            </div>
                                                            : el.status === 3
                                                                ? <div
                                                                    className='label text-danger ml-2' >
                                                                    <FontAwesomeIcon icon={faClose} />
                                                                    <span className='ml-1'>{t('reject')}</span>

                                                                </div>
                                                                : <div
                                                                    className='label text-secondary ml-2' >
                                                                    <FontAwesomeIcon icon={faHourglass} />
                                                                    <span className='ml-1'>{t('pending')}</span>
                                                                </div>
                                                    }
                                                </div>
                                            </div>
                                        </h2>
                                        <div className='col'>
                                            {el.message && <div className='text-danger font-12'>({el.message})</div>}
                                        </div>

                                    </div>


                                    <div id={"collapse" + el.id}
                                        className="collapse"
                                        aria-labelledby={"heading" + el.id}
                                        data-parent="#accordionApproval"
                                    >
                                        <div className=" ">
                                            <div className="col my-2">
                                                <div className="d-flex">
                                                    <div>{t(el.payload?.cust_name)}</div>
                                                    <div>{t(el.payload?.header)}</div>
                                                </div>

                                            </div>
                                            <table className='table border-bottom font-12'>
                                                <tbody>
                                                    {
                                                        el.payload && el.payload?.items?.length > 0 && el.payload?.items?.map((item, i) => (
                                                            <tr key={i + 1}>
                                                                <td width="20px" style={{ borderRight: '0.08em solid rgb(235 232 232)' }}>{i + 1}</td>
                                                                <td>{item.item_code}</td>
                                                                <td>{item.name}</td>
                                                                <td className='text-right'>{item.qty}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                            <div className='font-10 mx-2 mb-2'>{el.remark}</div>
                                            <div className='font-10 mx-2 mb-2'>{el.payload?.remark}</div>
                                            <div className=" m-2">
                                                <Attached payload={el.payload} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        : <div className='text-center'>{t('There are no records to display')}</div>
                }

            </div>


        </Template>
    );
}

export default ApproveHistory;
