import React from 'react';
import { useTranslation } from 'react-i18next';
import Template from '../../component/template';
import { useParams } from "react-router-dom";

const Notify = () => {
    const { t } = useTranslation();
    let { id } = useParams();

    console.log('id::', id);
    return (
        <Template>
            Approval Notify

        </Template>
    );
}

export default Notify;
