import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Template from '../../component/template';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faEye } from '@fortawesome/free-solid-svg-icons';
import global from '../../api/global';
import moment from 'moment';
 

const Profile = () => {
    const { t } = useTranslation();
     
    return (
        <Template >
            Profile
        </Template>
    );
}

export default Profile;
