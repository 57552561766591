import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/home';

import NotFound from './pages/NotFound';
import Login from './pages/login';
import Register from './pages/register';
import ForgotPassword from './pages/forgot-password';
import Approval from './pages/approval';
import ApprovalHistory from './pages/approval/history';
import Notify from './pages/approval/notify';
import Profile from './pages/user/profile';
import Setting from './pages/user/setting';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/approval" element={<Approval />} />
        <Route path="/approval/:id" element={<Approval />} />
        <Route path="/approval/notify/:id" element={<Notify />} />
        <Route path="/approval/history" element={<ApprovalHistory />} />
        <Route path="/user/profile" element={<Profile />} />
        <Route path="/user/setting" element={<Setting />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
